import React from "react";
import LoginSignUp from "../components/LoginSignUp";

const User = () => {
  return(
    <React.Fragment>
      <LoginSignUp />
    </React.Fragment>
  )
}

export default User